.pagination{
    gap: 10px;
    .next{
        border: 1px solid #e9ebec;
        padding: .375rem .75rem;
        a{
            color: #000;
            transition: 250ms;
        }
        &:hover{
           a{
            color: rgb(1, 153, 156);
           }
        }
    }
    .previous{
        border: 1px solid #e9ebec;
        padding: .375rem .75rem;
        color: blue;
        a{
            color: #000;
            transition: 250ms;
        }
        &:hover{
            a{
                color: rgb(1, 153, 156);
            }
        }
    }
    .active{
        color: rgb(1, 153, 156) !important;
        font-weight: 500;
        padding: .375rem .75rem;
        border: 1px solid #e9ebec;
    }
    .inActive{
        color: #000;
        padding: .375rem .75rem;
        border: 1px solid #e9ebec;
        
    }

}
