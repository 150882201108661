
@import "pagination.scss" ;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
.spinner {
    
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: radial-gradient(farthest-side,#00C2CB 94%,#0000) top/9px 9px no-repeat,
           conic-gradient(#fff 30%,#00C2CB);
    -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 9px),#000 0);
    animation: spinner-c7wet2 1s infinite linear;
 }
 
 @keyframes spinner-c7wet2 {
    100% {
       transform: rotate(1turn);
    }
 }
.mygrouph1{
   font-family: "Montserrat",sans-serif;
   font-weight: bold;
}

.invalid_message{
   color: red;
}